import 'stylesheets/flat_2016/custom/64/index.scss';
import 'javascripts/flat_2016/index';
import log from "libs/Utils/log";
import isMobile from "libs/Utils/isMobile";

window.layzrThreshold = isMobile.mobile() ? 50 : 100;

checkDate();

function checkDate() {
  const headerDate = document.querySelector(".header-top__date");

  if (headerDate) {
    log("CheckDate:init");

    let newDate = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  
    headerDate.innerHTML = `<span>${newDate.toLocaleDateString('de-DE', options)}</span>`
  }
}
